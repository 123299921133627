
import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pokemon from "../components/pokemon"

const PokemonListPage = ({ data, location }) => {
  const [search, setSearch] = React.useState("")
  const rawPokemons = data.allMarkdownRemark.edges
  const [pokemons, setPokemons] = React.useState(rawPokemons)
  const [isLoading, setIsLoading] = React.useState(true)
  const siteTitle = data.site.siteMetadata.title

  React.useLayoutEffect(() => {
    setPokemons(
      rawPokemons.filter(pokemon => {
        if (!search || search.length <= 2) {
          return true
        } else {
          return pokemon.node.frontmatter.name.toLowerCase().includes(search)
        }
      })
    )
  }, [search])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Fire Type Pokémon GO"  description="Everything you need to know about Fire type Pokémons in Pokémon GO: who are they? How many Fire Pokémon? What are their Weaknesses? How powerful are they?"  slug={location.pathname}/>

      <h1>Fire Type Pokémon GO</h1>

        <p>There are {pokemons.length} fire types in Pokemon GO.</p>

      <p>
        As seen on our{" "}
        <Link to="/pokemon-go-type-chart/">Pokémon GO Type Chart</Link>, fire type
        Pokémons are weak against GROUND ROCK WATER and resistant to BUG FIRE FAIRY GRASS ICE STEEL attacks.
      </p>

      <h2>Best Fire Type Pokemon GO</h2>

      <p>
        Here is a list of all the Fire Type Pokemons currently implemented in
        Pokemon GO:
      </p>


      <input
        value={search}
        onChange={e => setSearch(e.target.value)}
        style={{ width: "100%" }}
        placeholder="Search for a Fire Type Pokemon..."
      />

      <div>
        {pokemons.map(pokemon => {
          return (
            <Link
              key={`${pokemon.node.frontmatter.id}-${pokemon.node.frontmatter.form}`}
              to={`/pokemon-go/${
                pokemon.node.frontmatter.id
              }-${pokemon.node.frontmatter.name.toLowerCase()}/`}
            >
              <Pokemon pokemon={pokemon.node.frontmatter} />
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default PokemonListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___id], order: ASC }
      filter: {
        fileAbsolutePath: {
          regex: "/.*/pokemon/(4|5|6|37|38|58|59|77|78|126|136|146|155|156|157|218|219|228|229|240|244|250|255|256|257)-.*/"
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            name
            form
            type1
            type2
            maxCp
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
